html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
input {
  border: 0;
  outline: 0;
}
input:focus {
  outline: none !important;
}
textarea {
  border: 0;
  outline: 0;
  resize: none;
}
textarea:focus {
  outline: none !important;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
